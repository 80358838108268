
import React from "react";
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };


  console.log("tony is in history !!!!!!!");
  // console.log("Tony Domain  " + domain);
  // console.log("Tony Client ID  " + clientId);
  console.log("TonyuseHistory  " + window.location.origin);
  console.log("New var tony " + process.env.REACT_APP_TONY);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
