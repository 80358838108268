import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
// import { useAuth0 } from "@auth0/auth0-react";
import Loading from './components/Loading';
import { NavigationBar } from './components/NavigationBar';
import  Jumbotron  from './components/Jumbotron'
import { Routes } from 'react-router-dom';
import { Layout } from './components/Layout';

//Lazy load components
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Regions = lazy(() => import('./Regions'));
const Races = lazy(() => import('./Races'));
const Dwarves = lazy(() => import('./races/Dwarves'));
const Elves = lazy(() => import('./races/Elves'));
const Humans = lazy(() => import('./races/Humans'));
const Gnomes = lazy(() => import('./races/Gnomes'));
const Desc = lazy(() => import('./races/Desc'));
const Orcs = lazy(() => import('./races/Orcs'));
const Other = lazy(() => import('./races/Other'));
const Tony = lazy(() => import('./Tony'));
const NoMatch = lazy(() => import('./NoMatch'));
const Profile = lazy(() => import('./Profile'));
const Stories = lazy(() => import('./stories/Stories'));
const FirstPeople = lazy(() => import('./stories/FirstPeople'));
const Calmindor = lazy(() => import('./regions/Calmindor'));
const Clemente = lazy(() => import('./regions/Clemente'));
const GladeMore = lazy(() => import('./regions/GladeMore'));
const Hashdale = lazy(() => import('./regions/Hashdale'));
const Metador = lazy(() => import('./regions/Metador'));
const Roust = lazy(() => import('./regions/Roust'));
const Wren = lazy(() => import('./regions/Wren'));
const Wallbreak = lazy(() => import('./Wallbreak'));

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <NavigationBar />
      <Jumbotron />
      <div className="app-container">
        <Layout>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/regions" element={<Regions />} />
              <Route path="/races" element={<Races />} />
              <Route path="/races/desc" element={<Desc />} />
              <Route path="/races/dwarves" element={<Dwarves />} />
              <Route path="/races/elves" element={<Elves />} />
              <Route path="/races/humans" element={<Humans />} />
              <Route path="/races/gnomes" element={<Gnomes />} />
              <Route path="/races/orcs" element={<Orcs />} />
              <Route path="/races/other" element={<Other />} />
              <Route path="/stories/Stories" element={<Stories />} />
              <Route path="/stories/FirstPeople" element={<FirstPeople />} />
              <Route path="/regions/Calmindor" element={<Calmindor />} />
              <Route path="/regions/Wren" element={<Wren />} />
              <Route path="/regions/Clemente" element={<Clemente />} />
              <Route path="/regions/GladeMore" element={<GladeMore />} />
              <Route path="/regions/Hashdale" element={<Hashdale />} />
              <Route path="/regions/Metador" element={<Metador />} />
              <Route path="/regions/Roust" element={<Roust />} />
              <Route path="/tony" element={<Tony />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/wallbreak" element={<Wallbreak />} />
              <Route element={<NoMatch />} />
              {/* Add other routes */}
            </Routes>
          </Suspense>
        </Layout>
      </div>
    </Suspense>
  );
}

export default App;



// import React, { Suspense, lazy } from 'react';
// import './App.css';
// import { BrowserRouter as Router } from 'react-router-dom';
// // import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { Route, Switch } from 'react-router-dom';
// import { withAuth0, useAuth0 } from "@auth0/auth0-react";
// import Loading from './components/Loading';
// import { Layout } from './components/Layout';
// import { NavigationBar } from './components/NavigationBar';
// import  Jumbotron  from './components/Jumbotron';
// import Sidebar from './components/SideBar';
// import { Routes } from 'react-router-dom';

// // Lazy load components
// const Home = lazy(() => import('./Home'));
// const About = lazy(() => import('./About'));
// const Regions = lazy(() => import('./Regions'));
// const Races = lazy(() => import('./Races'));
// const Dwarves = lazy(() => import('./races/Dwarves'));
// const Elves = lazy(() => import('./races/Elves'));
// const Humans = lazy(() => import('./races/Humans'));
// const Gnomes = lazy(() => import('./races/Gnomes'));
// const Desc = lazy(() => import('./races/Desc'));
// const Orcs = lazy(() => import('./races/Orcs'));
// const Other = lazy(() => import('./races/Other'));
// const Tony = lazy(() => import('./Tony'));
// const NoMatch = lazy(() => import('./NoMatch'));
// const Profile = lazy(() => import('./Profile'));
// const Stories = lazy(() => import('./stories/Stories'));
// const FirstPeople = lazy(() => import('./stories/FirstPeople'));
// const Calmindor = lazy(() => import('./regions/Calmindor'));
// const Clemente = lazy(() => import('./regions/Clemente'));
// const GladeMore = lazy(() => import('./regions/GladeMore'));
// const Hashdale = lazy(() => import('./regions/Hashdale'));
// const Metador = lazy(() => import('./regions/Metador'));
// const Roust = lazy(() => import('./regions/Roust'));
// const Wren = lazy(() => import('./regions/Wren'));
// const Wallbreak = lazy(() => import('./Wallbreak'));

// function App() {
//   const { isLoading } = useAuth0();
//   if (isLoading) {
//     return <Loading />;
//   }

//   return (
//     <React.Fragment>
//       <Router>
//         <NavigationBar />
       
//         <Jumbotron />
//         <div className="app-container">
//           {/* <Sidebar /> */}
//           <Layout>
//             <Suspense fallback={<Loading />}>
//               <Routes>
                // <Route exact path="/" component={Home} />
                // <Route path="/about" component={About} />
                // <Route path="/regions" exact component={Regions} />
                // <Route path="/races" exact component={Races} />
                // <Route path="/races/desc" exact component={Desc} />
                // <Route path="/races/dwarves" exact component={Dwarves} />
                // <Route path="/races/elves" exact component={Elves} />
                // <Route path="/races/humans" exact component={Humans} />
                // <Route path="/races/gnomes" exact component={Gnomes} />
                // <Route path="/races/orcs" exact component={Orcs} />
                // <Route path="/races/other" exact component={Other} />
                // <Route path="/stories/Stories" exact component={Stories} />
                // <Route path="/stories/FirstPeople" exact component={FirstPeople} />
                // <Route path="/regions/Calmindor" exact component={Calmindor} />
                // <Route path="/regions/Wren" exact component={Wren} />
                // <Route path="/regions/Clemente" exact component={Clemente} />
                // <Route path="/regions/GladeMore" exact component={GladeMore} />
                // <Route path="/regions/Hashdale" exact component={Hashdale} />
                // <Route path="/regions/Metador" exact component={Metador} />
                // <Route path="/regions/Roust" exact component={Roust} />
                // <Route path="/tony" component={Tony} />
                // <Route path="/Profile" component={Profile} />
                // <Route path="/wallbreak" component={Wallbreak} />
                // <Route component={NoMatch} />
//               </Routes>
//             </Suspense>
//             </Layout> 
//         </div>
       
//       </Router>
//     </React.Fragment>
//   );
// }

// export default withAuth0(App);
