import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';
import AuthNav from "./AuthNav";

const Styles = styled.div`
  .navbar {
    background-color: #222;
  }
  a, .navbar-brand, .navbar-nav .nav-link {
    color: #bbb;
    &:hover {
      color: white;
    }
  }
  .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  .navbar-toggler-icon{
    border-color: white;
  }
  /* change the background color */
  .navbar-custom {
      background-color: red;
  }
  /* change the link color */
  .navbar-custom .navbar-nav .nav-link {
      color: yellow;
  }
  /* change the color of active or hovered links */
  .navbar-custom .nav-item.active .nav-link,
  .navbar-custom .nav-item:hover .nav-link {
      color: black;
  }
`;

export const NavigationBar = () => (
  <Styles>
    <Navbar expand="lg" fixed="top" >
      <Navbar.Brand href="/">World Of Elrue</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/about">About</Nav.Link>
        <Nav.Link href="/wallbreak">Wallbreak</Nav.Link>
        <Nav.Link href="/regions">Regions</Nav.Link>
        <Nav.Link href="/stories/Stories">Stories</Nav.Link>
          <NavDropdown alignRight title="Races" id="dropdown-menu-align-right">
            <NavDropdown.Item href="/races/Desc">Races General</NavDropdown.Item>
            <NavDropdown.Divider />
            <h6 align="center">Humanoid Races</h6>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/races/Dwarves">Dwarves</NavDropdown.Item>
            <NavDropdown.Item href="/races/Humans">Humans</NavDropdown.Item>
            <NavDropdown.Item href="/races/Elves">Elves</NavDropdown.Item>
            <NavDropdown.Item href="/races/Gnomes">Gnomes</NavDropdown.Item>
            <NavDropdown.Item href="/races/Orcs">Orcs</NavDropdown.Item>
            <NavDropdown.Divider />
            <h6 align="center">Other Creatures</h6>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/races/Other">Other</NavDropdown.Item>
          </NavDropdown>
        <AuthNav />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Styles>
)


// const Styles = styled.div`
//   .navbar {
//     background-color: #222;
//   }
//   a, .navbar-brand, .navbar-nav .nav-link {
//     color: #bbb;
//     &:hover {
//       color: white;
//     }
//   }

