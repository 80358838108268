import React from "react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
// help from here -> https://auth0.com/blog/complete-guide-to-react-user-authentication/

const AuthenticationButton = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? 
    <div>
      <NavDropdown title="Admin" id="dropdown-menu-align-right">
        <NavDropdown.Item href="/Profile">User Profile Auth0</NavDropdown.Item>
      </NavDropdown>
      <LogoutButton />
</div>
  : <LoginButton />;
};

export default AuthenticationButton;

